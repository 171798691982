import { ReactNode } from 'react';
import { Flex, Box, css } from '@storyofams/react-ui';

import { Divider } from '~components';

interface SideLayoutProps {
  isOpen: boolean;
  renderSide: () => ReactNode;
  children: ReactNode;
}

export const SideLayout = ({
  isOpen,
  renderSide,
  children,
}: SideLayoutProps) => {
  return (
    <Flex mt={[0, 3]} height="max-content">
      <Box pr={isOpen ? [0, 3, 9] : 0}>
        <Box
          width={isOpen ? '258px' : 0}
          opacity={isOpen ? 1 : 0}
          flexDirection="column"
          display={['none', 'flex']}
        >
          <Divider alignSelf="flex-start" mb={2} />
          <Box mt={2} css={css({ '& div': { borderBottomWidth: 0 } })}>
            {renderSide()}
          </Box>
        </Box>
      </Box>
      <Flex width={['100%', isOpen ? 'calc(100% - 258px)' : '100%']} mt={5}>
        {children}
      </Flex>
    </Flex>
  );
};

export default SideLayout;
