import { useState, useMemo } from 'react';
import { Box, Flex, Icon } from '@storyofams/react-ui';
import { useRouter } from 'next/router';
import qs from 'qs';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { Divider, Text, Button } from '~components';
import { useShopify } from '~context';
import { Drawer } from '~components/common/Drawer';
import { ChevronRight } from '~components/common/Icons';
import { Filter } from '~components/products/Filter';
import { SideLayout } from '~components/products/SideLayout';
import { SortBy } from '~components/products/SortBy';
import { getShopifySdk } from '~lib/shopify/client';
import { ProductSortKeys } from '~lib/shopify/sdk';

import { Results } from './Results';

interface GridProps {
  collection?: string | string[];
  filtersMapping?: any;
  hidesFilters?: boolean;
  search?: string;
}

const messages = defineMessages({
  filtersHide: 'Hide filters',
  filtersShow: 'Show filters',
  reset: 'Reset',
  showResults: 'Show results',
});

const formatCollection = (collection: string | string[]) => {
  if (!collection || collection.length === 0) {
    return collection;
  }
  if (Array.isArray(collection)) {
    return `tag:${collection.map((c) => `"collection_${c}"`).join(' OR ')}`;
  }

  return `tag:"collection_${collection}"`;
};

export const changeTagsToQuery = (
  filters: { [index: string]: string[] },
  collection?: string | string[],
) => {
  const tags = Object.entries(filters)
    .map(([prefix, items]) => {
      if (typeof items === 'string') {
        return [`tag:${prefix}_${items}`];
      }
      return items.map((e) => `tag:${prefix}_${e}`);
    })
    .flat();

  return `${tags?.length ? `tags:${tags.join(' OR ')}` : ''}${
    collection ? ` ${formatCollection(collection)}` : ''
  }`;
};

export const getCustomerGroupTag = (customerTags) => {
  // always add customertags to filter for b2b purposes
  const customerTag = customerTags.filter((tag) => tag.includes('group'));
  return customerTag?.length > 0
    ? { group: customerTag.map((tag) => tag.replace('group_', '')) }
    : {};
};

const Grid = ({
  collection,
  filtersMapping,
  hidesFilters,
  search,
}: GridProps) => {
  const router = useRouter();
  const intl = useIntl();
  const { push, locale, query } = router;
  const { customer } = useShopify();

  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [isShowingMobileFilters, setIsShowingMobileFilters] = useState(false);

  const parsedQuery = useMemo(
    () =>
      (qs.parse(query?.tags as string) ?? []) as {
        [index: string]: string[];
      },
    [query],
  );

  const fetchProducts = async () => {
    let queryString = `${search ? `${search} ` : ''}${changeTagsToQuery(
      { ...parsedQuery, ...getCustomerGroupTag(customer?.tags ?? []) },
      collection,
    )}`;

    if (process.env.NEXT_PUBLIC_STORE_COUNTRY === 'us' && !query.slug) {
      if (!queryString || queryString === ' tag:' || queryString === ' ') {
        queryString += ' available_for_sale:true';
      } else {
        queryString += 'AND available_for_sale:true';
      }
    }

    const data = await getShopifySdk(locale).products({
      first: 250,
      sortKey: query?.sortKey as ProductSortKeys,
      reverse: query?.sortOrder && query?.sortOrder === 'DESC',
      query: queryString,
    });

    // sort products based on if product is in stock
    data.products.edges = data.products.edges.sort((a, b) =>
      a.node.availableForSale === b.node.availableForSale
        ? 0
        : a.node.availableForSale
        ? -1
        : 1,
    );
    return data.products;
  };

  const {
    data: products,
    isLoading,
    error,
  } = useQuery(['products', query, search], fetchProducts);

  const handleFilterChange = (filters: { [index: string]: string[] }) => {
    push({ query: { ...query, tags: qs.stringify(filters) } }, undefined, {
      shallow: true,
    });
  };

  const handleReset = () =>
    push(
      { query: { ...query, tags: undefined, search: undefined } },
      undefined,
      {
        shallow: true,
      },
    );

  return (
    <>
      <Divider mb={3} />

      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        {!hidesFilters && (
          <Box display={['none', 'flex']} flex="1">
            <Button
              alignItems="center"
              variant="unstyled"
              onClick={() => setIsShowingFilters(!isShowingFilters)}
            >
              <Text mr={1} fontSize={2} lineHeight="normal">
                {isShowingFilters
                  ? intl.formatMessage(messages.filtersHide)
                  : intl.formatMessage(messages.filtersShow)}
              </Text>
              <Icon
                icon={ChevronRight}
                transform={isShowingFilters ? 'rotate(90deg)' : ''}
                transition="transform 0.18s linear"
                fontSize={1.5}
              />
            </Button>
          </Box>
        )}

        {!isLoading && products?.edges?.length > 0 && (
          <Text lineHeight="normal" mb={[2, 0]} fontSize={1.75}>
            <FormattedMessage
              defaultMessage="Showing {amount} products"
              values={{ amount: products?.edges?.length }}
            />
          </Text>
        )}

        <Flex flex="1" justifyContent="flex-end" width={['100%', 'auto']}>
          <SortBy />
        </Flex>
      </Box>

      <SideLayout
        isOpen={!hidesFilters && isShowingFilters}
        renderSide={() =>
          !hidesFilters
            ? Object.keys(filtersMapping)
                ?.filter((title) => title !== '_editable')
                ?.map((title) => (
                  <Filter
                    key={title}
                    title={title.replace('_', ' ')}
                    options={filtersMapping?.[title]}
                    selectedOptions={parsedQuery?.[title] || []}
                    setSelectedOptions={(input) =>
                      handleFilterChange({ ...parsedQuery, [title]: input })
                    }
                  />
                ))
            : null
        }
      >
        <Results
          products={products}
          isLoading={isLoading}
          error={error}
          resetFilters={handleReset}
          isShowingFilters={isShowingFilters}
          hasFilters={query?.tags || query?.search}
        />
      </SideLayout>

      {!hidesFilters && (
        <Box
          display={['flex', 'none']}
          position="sticky"
          bottom={5}
          left={0}
          right={0}
          zIndex={1}
        >
          <Button
            alignItems="center"
            variant="primary"
            mx="auto"
            onClick={() => setIsShowingMobileFilters(!isShowingMobileFilters)}
          >
            <FormattedMessage
              defaultMessage="Filter {count}"
              values={{
                count: parsedQuery?.taste?.length
                  ? `(${parsedQuery?.taste?.length})`
                  : '',
              }}
            />
          </Button>
        </Box>
      )}
      <Drawer
        isOpen={isShowingMobileFilters}
        close={() => setIsShowingMobileFilters(false)}
        title={
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <FormattedMessage
              defaultMessage="Filter {count}"
              values={{
                count: parsedQuery?.taste?.length
                  ? `(${parsedQuery?.taste?.length})`
                  : '',
              }}
            />
            <Button variant="unstyled" mr={1} onClick={handleReset}>
              <Text
                lineHeight="normal"
                textAlign="right"
                color="grey500"
                fontWeight="normal"
              >
                {intl.formatMessage(messages.reset)}
              </Text>
            </Button>
          </Flex>
        }
      >
        <Flex flexDirection="column" height="100%" position="relative" pt={3}>
          {Object.keys(filtersMapping)
            .filter((title) => title !== '_editable')
            .map((title) => (
              <Filter
                key={title}
                title={title.replace('_', ' ')}
                options={filtersMapping?.[title]}
                selectedOptions={parsedQuery?.[title] || []}
                setSelectedOptions={(input) =>
                  handleFilterChange({ ...parsedQuery, [title]: input })
                }
              />
            ))}
          <Button
            position="absolute"
            bottom={5}
            left={0}
            right={0}
            zIndex={1}
            alignItems="center"
            variant="primary"
            mx="auto"
            onClick={() => setIsShowingMobileFilters(!isShowingMobileFilters)}
          >
            {intl.formatMessage(messages.showResults)}
          </Button>
        </Flex>
      </Drawer>
    </>
  );
};

export default Grid;
