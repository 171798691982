import { css } from '@storyofams/react-ui';
import { useRouter } from 'next/router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { components } from 'react-select';

import { Select, Text } from '~components';
import { ProductSortKeys } from '~lib/shopify/sdk';

const messages = defineMessages({
  popular: 'Popular',
  alphabeticalAsc: 'Alphabetically, A-Z',
  alphabeticalDesc: 'Alphabetically, Z-A',
  priceAsc: 'Price, low-high',
  priceDesc: 'Price, high-low',
  sortBy: 'Sort by',
});

const sortOptions = [
  {
    key: ProductSortKeys.BestSelling,
    message: messages.popular,
  },
  {
    key: ProductSortKeys.Title,
    message: messages.alphabeticalAsc,
    sortOrder: 'ASC',
  },
  {
    key: ProductSortKeys.Title,
    message: messages.alphabeticalDesc,
    sortOrder: 'DESC',
  },
  {
    key: ProductSortKeys.Price,
    message: messages.priceAsc,
    sortOrder: 'ASC',
  },
  {
    key: ProductSortKeys.Price,
    message: messages.priceDesc,
    sortOrder: 'DESC',
  },
];

export const SortBy = () => {
  const { push, query } = useRouter();
  const intl = useIntl();

  const handleSortChange = (sortIdx: string) => {
    const option = sortOptions[parseInt(sortIdx, 10)];

    push(
      {
        query: {
          ...query,
          sortKey: option.key,
          sortOrder: option.sortOrder || 'ASC',
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return (
    <Select
      isSearchable={false}
      isDisabled={false}
      onChange={({ value }) => handleSortChange(value)}
      defaultValue={{
        label: intl.formatMessage(sortOptions[0].message),
        value: '0',
      }}
      options={sortOptions.map(({ message }, idx) => ({
        label: intl.formatMessage(message),
        value: `${idx}`,
      }))}
      disableShadow
      components={{
        Control: (props) => (
          <components.Control {...props}>
            <Text
              pr={0.25}
              pl={2}
              pt={0.75}
              pb={0.5}
              fontWeight="normal"
              fontSize={[2, 1.75]}
              lineHeight="high"
              color="grey500"
            >
              <FormattedMessage {...messages.sortBy} />
            </Text>
            {props.children}
          </components.Control>
        ),
      }}
      minWidth={['100%', '235px']}
      css={css({
        '&.react-select': {
          width: '100%',
          fontSize: [2, 1.75],
        },
        '& .react-select__control': {
          cursor: 'pointer',
          minHeight: ['48px', '42px'],
        },
        '& .react-select__option': {
          fontSize: [2, 1.75],
        },
      })}
    />
  );
};

export default SortBy;
