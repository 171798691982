import { Box, Grid } from '@storyofams/react-ui';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import { Button, Text } from '~components';

export type FilterOption = { title: string; tag: Record<string, any> };

export type FilterProps = {
  title: string;
  options: FilterOption[];
  selectedOptions: string[];
  setSelectedOptions: (input: string[]) => void;
};

const Container = styled.div`
  & > div {
    border-color: transparent;
  }
`;

const SelectButton = styled(Button)<{ selected: boolean }>`
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(p) =>
    p.selected ? p.theme.colors.grey900 : p.theme.colors.grey300};
  background-color: ${(p) =>
    p.selected ? p.theme.colors.grey900 : 'transparent'};
  color: ${(p) => (p.selected ? 'white' : p.theme.colors.grey900)};
  font-size: 14px;
  line-height: 100%;
  padding: ${(p) => p.theme.space[2]}px 0;
  transition: 0.18s all ease-in-out;
  justify-content: center;
  text-align: center;
`;

export const Filter = ({
  title,
  options,
  selectedOptions,
  setSelectedOptions,
}: FilterProps) => {
  const updateSelectedOptions = (selected: FilterOption) => {
    const item = selected.tag.item;
    if (selectedOptions.includes(item)) {
      setSelectedOptions([...selectedOptions].filter((e) => e !== item));
    } else {
      setSelectedOptions([...selectedOptions, item]);
    }
  };

  return (
    <Container>
      <Box>
        <Text mb={2}>{capitalize(title)}</Text>

        <Grid width="100%" rowGap={1} columnGap={1} rowSize={2}>
          {options.map((part, index) => (
            <Box key={part.title + index} width="100%">
              <SelectButton
                selected={selectedOptions.includes(part.tag.item)}
                onClick={() => updateSelectedOptions(part)}
                variant="unstyled"
                width="100%"
              >
                {part.title}
              </SelectButton>
            </Box>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
