import { useEffect, useState } from 'react';
import { Box, Flex, SystemProps, css } from '@storyofams/react-ui';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Heading } from '~components/common/Heading';
import { Image } from '~components/common/Image';
import { Text } from '~components/common/Text';
import { useVisibility } from '~hooks/useVisibility';
import { track } from '~lib/gtag';
import { decodeShopifyId } from '~lib/shopify/utils';

type ProductListingProps = {
  productImage: string;
  title: string;
  price: string;
  handle: string;
  readyToEnjoy?: boolean;
  position?: number;
  currencyCode: string;
  id: string;
  vendor: string;
  category: string;
  amount: string;
} & SystemProps;

export const ProductListing = ({
  productImage,
  title,
  price,
  handle,
  readyToEnjoy,
  ...props
}: ProductListingProps) => {
  const { asPath } = useRouter();
  const [isVisible, currentElement] = useVisibility();
  const [hasBeenTracked, setHasBeenTracked] = useState<boolean>(false);

  useEffect(() => {
    if (isVisible && !hasBeenTracked) {
      setHasBeenTracked(true);
      track({
        event: 'productImpression',
        ecommerce: {
          value: props.amount,
          currencyCode: props.currencyCode,
          impressions: [
            {
              id: decodeShopifyId(props.id),
              name: title,
              price: props.amount,
              brand: 'BOLS',
              category: props.category,
              position: props.position,
              list:
                asPath === '/'
                  ? 'home'
                  : asPath.includes('shop')
                  ? 'category'
                  : 'related_products',
            },
          ],
        },
      });
    }
  }, [isVisible]);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      // allow flex to use a ref
      {...({ ref: currentElement } as any)}
    >
      <Link href="/products/[handle]" as={`/products/${handle}`} passHref>
        <Flex
          as="a"
          onClick={() => {
            track({
              event: 'productClick',
              ecommerce: {
                value: props.amount,
                currencyCode: props.currencyCode,
                click: {
                  actionField: {
                    list:
                      asPath === '/'
                        ? 'home'
                        : asPath.includes('shop')
                        ? 'category'
                        : 'related_products',
                  },
                  products: [
                    {
                      id: decodeShopifyId(props.id),
                      name: title,
                      price: props.amount,
                      brand: props.vendor,
                      category: props.category,
                      position: props.position,
                    },
                  ],
                },
              },
            });
          }}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          position="relative"
          bg="white"
          css={css({
            img: {
              objectFit: 'contain',
            },
          })}
          {...props}
        >
          <Flex
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="center"
            height={['220px', '320px']}
            width="100%"
          >
            {productImage && (
              <Image
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={readyToEnjoy ? undefined : ['220px', '320px']}
                objectPosition="bottom"
                borderRadius={readyToEnjoy ? '50%' : undefined}
                overflow="hidden"
                pt="100%"
                width="100%"
                layout="fill"
                src={productImage}
                alt={title}
              />
            )}
          </Flex>
          <Box zIndex={1} textAlign="center">
            <Heading
              variant="h1"
              as="h3"
              fontSize={[2.5, 4]}
              lineHeight="normal"
              mb={1}
              mt={[3, 4]}
            >
              {title}
            </Heading>
            <Text
              fontSize={[1.75, 2]}
              lineHeight={['18px', 'medium']}
              pb={0.25}
            >
              {price}
            </Text>
          </Box>
        </Flex>
      </Link>
    </Flex>
  );
};
