import { Flex, Grid, Spinner } from '@storyofams/react-ui';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Button, Heading } from '~components';
import { useShopify } from '~context';
import { shopifyToIntlPrice } from '~lib/shopify/utils/shopifyToIntlPrice';

import { ProductListing } from './ProductListing';

const messages = defineMessages({
  errorResults: 'An error occurred while trying to load results.',
  noResults: 'No results found.',
  noResultsB2B:
    'No results were found for this account. Please contact Bols to finish your account setup.',
  clearFilters: 'Clear filters',
  from: 'From',
});

export const Results = ({
  products,
  error,
  isLoading,
  hasFilters,
  resetFilters,
  isShowingFilters,
}) => {
  const intl = useIntl();
  const { customer } = useShopify();
  const isB2B =
    process.env.NEXT_PUBLIC_STORE_COUNTRY === 'b2b' && !!customer.tags?.length;

  if (isLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        flex={1}
        minHeight={['200px', '300px']}
      >
        <Spinner color="grey500" />
      </Flex>
    );
  } else if (error) {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
        minHeight={['200px', '300px']}
      >
        <Heading
          variant="h3"
          as="h3"
          textAlign="center"
          lineHeight="150%"
          pb={[3, 6]}
          px={2}
          mt={[0, '-16px']}
        >
          <FormattedMessage {...messages.errorResults} />
        </Heading>
      </Flex>
    );
  } else if (products?.edges?.length <= 0) {
    return (
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
        minHeight={['200px', '300px']}
      >
        <Heading
          variant="h3"
          as="h3"
          textAlign="center"
          lineHeight="150%"
          px={2}
          mt={[0, '-16px']}
        >
          <FormattedMessage {...messages.noResults} />
        </Heading>
        {isB2B && <FormattedMessage {...messages.noResultsB2B} />}
        {hasFilters && (
          <Button mt={3} variant="primary" onClick={resetFilters}>
            <FormattedMessage {...messages.clearFilters} />
          </Button>
        )}
      </Flex>
    );
  }

  return (
    <Grid
      width="100%"
      rowGap={[5, 120]}
      columnGap={[4, 5]}
      rowSize={[2, 2, 3, isShowingFilters ? 3 : 4]}
    >
      {products?.edges?.map(({ node }, index) => (
        <ProductListing
          key={node.id}
          productImage={node.images.edges[0]?.node.url}
          title={node.title}
          price={`${
            node?.variants?.edges?.length > 1
              ? `${intl.formatMessage(messages.from)} `
              : ''
          }${shopifyToIntlPrice(node.priceRange.minVariantPrice)}`}
          handle={node.handle}
          readyToEnjoy={node?.readyToEnjoy?.value === 'true'}
          position={index + 1}
          currencyCode={node.priceRange?.minVariantPrice?.currencyCode}
          amount={node.priceRange?.minVariantPrice?.amount}
          id={node.id}
          vendor="BOLS"
          category={node.tags
            ?.find((tag) => tag?.startsWith('collection_'))
            ?.replace('collection_', '')}
        />
      ))}
    </Grid>
  );
};
